import { useRouter } from "next/router";
import { LogoIcon } from "../../../assets/logo.icon";
import { memo, useState } from "react";
import { Button } from "../button/button";
import ArrowUpIcon from "./assets/arrow.icon";
import CheckIcon from "./assets/check.icon";
import { MenuItem } from "../../../model/main-page-data";
import { toClassName } from "../../../utils/style.utils";

interface Props {
  loginBtnTitle: string;
  menu: MenuItem[];
  theme?: keyof typeof themes;
  color?: string;
  hasRedirectToHomePage?: boolean;
}

const themes = {
  light: {
    text: "text-night-base-base-100",
    backgroundColor: "bg-day-base-base-100",
    fill: "#22282B",
    border: "border-night-base-base-100",
  },
  dark: {
    text: "text-night-base-base-900",
    backgroundColor: "bg-white",
    fill: "#EEF1F2",
    border: "border-white-opacity-03",
  },
};

const localLabel: Record<string, string> = {
  en: "En",
  pt: "Pt",
  es: "Es",
  ru: "Рус",
  ko: "한국어",
  ja: "日本語",
};

const languages = [
  { label: "en", name: "English" },
  { label: "pt", name: "Português" },
  { label: "es", name: "Español" },
  { label: "ru", name: "Русский" },
  { label: "ko", name: "Korean" },
  { label: "ja", name: "Japanese" },
];

export const Navbar = memo<Props>(
  ({
    menu,
    loginBtnTitle,
    theme = "dark",
    color = "#fff",
    hasRedirectToHomePage = false,
  }) => {
    const router = useRouter();
    const currentLocale = router.locale!;
    const currentTheme = themes[theme];

    const textColor = {
      color: color,
    };
    const borderColor = {
      borderColor: color,
    };

    const lineColor = {
      backgroundColor: color,
    };

    const [isShown, setIsShown] = useState<boolean>(false);
    const [isShownLocale, setIsShownLocale] = useState<boolean>(false);
    const [currentLabel, setCurrentLabel] = useState<string>(currentLocale);

    function changeLocale(nextLocale: string) {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, { locale: nextLocale });
    }

    function toggleBodyScroll(isShown: boolean) {
      if (typeof window === "undefined") return;
      if (isShown) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    }

    const handleMenuItemClick = () => {
      setIsShown(false);
      toggleBodyScroll(false);
    };

    return (
      <nav
        role="navigation"
        className={toClassName(
          "ml-auto mr-auto relative max-w-[1440px] items-center bg-transparent pl-10 pr-10 lg:pl-20 lg:pr-20 xxxxl:pl-0 xxxxl:pr-0",
          currentTheme.text
        )}
        style={textColor}
      >
        <div className="flex items-center justify-between h-[65px] lg:h-[80px] relative">
          <a href="/" className="z-50" aria-label="To the main page">
            <LogoIcon fill={color ? color : "#fff"} />
          </a>
          <ul className="justify-between text-[16px] sm:hidden lg:flex">
            {menu.map((item) => (
              <li className="mr-20 last:mr-0" key={item._key}>
                <a
                  href={hasRedirectToHomePage ? `/#${item.id}` : `#${item.id}`}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>

          <div className="flex items-center relative">
            <div
              onClick={() => setIsShownLocale((currentState) => !currentState)}
              className="pr-12 flex items-center"
            >
              <div className="cursor-pointer pr-3 text-[16px] uppercase">
                {localLabel[currentLabel]}
              </div>
              <div
                className={
                  isShownLocale
                    ? "transform transition-transform cursor-pointer"
                    : "rotate-[-180deg] transition-transform cursor-pointer"
                }
              >
                <ArrowUpIcon fill={color ? color : "#fff"} />
              </div>
              <div
                className={
                  isShownLocale
                    ? toClassName(
                      "w-[240px] rounded-[12px] p-4 absolute top-[25px] right-[0px] lg:top-[45px] z-10 lg:right-[20px] bg-white text-day-base-base-900",
                      currentTheme.backgroundColor
                    )
                    : "hidden"
                }
              >
                {languages.map((language) => (
                  <div key={language.label}>
                    {["ru", "en", "pt", "es"].includes(language.label) ? (
                      <div
                        onClick={() => {
                          changeLocale(language.label);
                          setCurrentLabel(language.label);
                        }}
                        className="cursor-pointer text-[16px] leading-[130%] px-4 py-2 hover:bg-day-base-base-100 rounded-[6px] flex justify-between items-center"
                      >
                        <div>{language.name}</div>
                        {currentLabel === language.label && <CheckIcon />}
                      </div>
                    ) : (
                      <div className=" text-[16px] leading-[130%] px-4 py-2 text-day-base-base-400 flex justify-between items-center">
                        <span>{language.name}</span>
                        <span className="text-[12px]"> Coming soon</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              className="z-50 relative cursor-pointer flex flex-col justify-start lg:hidden"
              onClick={() => {
                setIsShown(!isShown);
                toggleBodyScroll(!isShown);
              }}
            >
              <div
                className={`relative w-[32px] h-[10px] flex flex-col justify-around items-center`}
              >
                <div
                  style={lineColor}
                  className={`w-[24px] h-[2px] rounded-[10px] transition-transform duration-300 ${
                    isShown ? "transform rotate-45 translate-y-[1px]" : ""
                  }`}
                ></div>

                <div
                  style={lineColor}
                  className={`w-[24px] h-[2px] mt-3 rounded-[10px] transition-transform duration-300 ${
                    isShown ? "transform -rotate-45 -translate-y-[7px]" : ""
                  }`}
                ></div>
              </div>
            </div>

            <Button
              isLink
              className="hidden lg:flex leading-[130%]"
              size="s"
              theme={
                theme === "light"
                  ? "primary-transparent-light-theme"
                  : "primary-transparent"
              }
              href="https://learn.guidedao.xyz/"
              target="_blank"
              rel="noreferrer"
              style={borderColor}
            >
              <span style={textColor} className="relative top-[1px]">
                {loginBtnTitle}
              </span>
            </Button>
          </div>
          <div
            className={
              isShown
                ? toClassName(
                  "fixed top-[0px] left-[0px] z-40 h-screen w-screen bg-black pl-10 pr-10 pt-66 lg:hidden",
                  currentTheme.backgroundColor
                )
                : "hidden"
            }
          >
            <ul className="justify-between text-base-m">
              {menu.map((item) => (
                <li
                  className={toClassName(
                    "border-t border-solid pt-10 pb-10 text-white",
                    currentTheme.border
                  )}
                  onClick={handleMenuItemClick}
                  key={item._key}
                >
                  <a
                    href={
                      hasRedirectToHomePage ? `/#${item.id}` : `#${item.id}`
                    }
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
);
