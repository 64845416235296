import { SyntheticEvent, useCallback, useState } from "react";
import { LeadType } from "../model/leads";
import { track } from "../utils/gtm.utils";
import { Promocode } from "../model/promocode";
import * as Sentry from "@sentry/nextjs";
import { isEvent } from "../utils/type.utils";
import { Constraints, useValidation, ValidationError } from "./use-validation";

export interface CrmData {
  formId: string;
  bootcampName?: string;
}

interface LeadData {
  email?: string;
}

interface Options {
  params?: {
    bootcampName?: string;
    promocode?: Promocode;
    priceEth?: number;
    priceRub?: string;
    priceUsdt?: string;
    priceRubWithDiscount?: number;
    priceUsdtWithDiscount?: number;
    priceEthWithDiscount?: number;
    locale?: string;
  };
  constraints?: Constraints;
  crmData: CrmData;
  onError: (error: Error) => void;
}

interface UseLeadsHook<T> {
  isLoading: boolean;
  isSuccess: boolean;
  data: T;
  submit: () => void;
  validationErrors: ValidationError[];
  onInputChanged: (
    event: SyntheticEvent<HTMLInputElement> | InputHandlerProps
  ) => void;
}
interface InputHandlerProps {
  name: string;
  value: string;
}

export function useLeadsForm<T extends LeadData>(
  leadType: LeadType,
  { onError, params, crmData, constraints }: Options
): UseLeadsHook<Partial<T>> {
  const [data, setData] = useState<Partial<T>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { validationErrors, validate, resetValidation } = useValidation<
    Partial<T>
  >({ constraints });

  const submit = useCallback(async () => {
    setIsSuccess(false);
    setIsLoading(true);
    const isValid = validate(data);
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const createdAt = new Date();

    fetch(`/api/leads/${leadType}`, {
      method: "POST",
      body: JSON.stringify({
        createdAt,
        ...data,
        ...(params || {}),
        crmData,
        isSendingToNotionEnabled: constraints?.isSendingToNotionEnabled,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const content = await response.text();
          Sentry.captureException(`Ошибка в форме: ${content}`);
          throw new Error(`Failed to submit form: ${content}`);
        }

        return true;
      })
      .then(async () => {
        if (constraints?.isAddingToParagraphEnabled && data.email) {
          fetch(`https://paragraph.xyz/api/blogs/@guidedao/subscribe`, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify({ email: data.email }),
          }).catch((error) => {
            Sentry.captureException(
              `Не получилось добавить имейл в paragraph: ${error}`
            );
          });
        }

        try {
          if (process.env.NODE_ENV === "production") {
            track("form_submit");
            fbq("track", "SubmitApplication");
          }
        } catch (e) {
          Sentry.captureException("Ошибка в форме", e);
          console.error(e);
        }
        setData({});
        setIsSuccess(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
        onError(e);
      })
      .finally(() => setIsLoading(false));
  }, [validate, data, leadType, params, onError, crmData, constraints]);

  const onInputChanged = useCallback(
    (eventOrProps: SyntheticEvent<HTMLInputElement> | InputHandlerProps) => {
      const { name, value } = isEvent(eventOrProps)
        ? eventOrProps.currentTarget
        : eventOrProps;

      resetValidation();
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [resetValidation]
  );

  return {
    data,
    validationErrors,
    submit,
    isLoading,
    isSuccess,
    onInputChanged,
  };
}
