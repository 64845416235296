import { Promocode } from "./promocode";

export enum LeadType {
  STUDENT = "students",
  SUBSCRIBER = "subscribers",
}

export interface StudentLead {
  email: string;
  telephone?: string;
  telegram?: string;
  name: string;
  bootcampName?: string;
  priceEth?: number;
  priceRub?: number;
  priceUsdt?: number;
  priceRubWithDiscount?: number;
  priceUsdtWithDiscount?: number;
  priceEthWithDiscount?: number;
  promocode?: Promocode;
  captchaToken: string;
  createdAt: string;
  locale?: string;
}

export interface SubscriberLead {
  email: string;
  createdAt: string;
}
