const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const VALIDATIONS = {
  VALID_TELEPHONE: {
    name: "telephone",
    validate: (value: string) => {
      const onlyNumbers = value.replace(/\D/g, "");
      return onlyNumbers.length > 7;
    },
  },
  VALID_TELEGRAM: {
    name: "telegram",
    validate: (value: string) =>
      /^@?[A-Za-z][A-Za-z0-9_]{4,31}$/.test(value) && value.length >= 5,
  },
  VALID_NAME: {
    name: "name",
    validate: (value: string) => value.length > 2,
  },
  VALID_EMAIL: {
    name: "email",
    validate: validateEmail,
  },
};
