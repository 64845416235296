import Lottie from "react-lottie";
import animationChicken from "./chicken-success.json";

export const LottieChicken = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationChicken,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} isStopped={false} width={300} />
    </div>
  );
};
