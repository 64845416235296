import { ElementProps } from "../types/common";

export const LogoIcon = ({ fill = "#fff", ...props }: ElementProps) => (
  <svg
    width={80}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.284.292H5.067C2.274.292 0 2.641 0 5.53l.001 7.776 4.233 4.355h9.977l4.252-4.351 4.253 4.35h9.142l4.253-4.35.004.005a5.42 5.42 0 0 0 .057-.788V.292h-6.333v10.956h-5.555V.292ZM6.333 11.2V6.808h.913v.006H17.96v1.31H7.246v3.075h-.913ZM55.187.289H37.528v17.422h17.766l4.138-4.233 4.138 4.233h15.84v-4.673H65.036v-1.31H79.41v-5.3H65.036v-1.31H79.41V.29L63.677.289 59.432 4.63 55.187.289Zm1.223 5.576v6.16h-6.021v-6.16h6.02Zm-10.164-3.38V15.5h-6.69V2.486h6.69Z"
      fill={fill}
    />
  </svg>
);
