export function sanitizeImageUrl(url: string): string | undefined {
  if (!url) {
    return;
  }
  const cdnImageUrl = "https://cdn.sanity.io/images/9kyppba9/production/";
  const fileName = url
    ?.replace(/^image-/, "")
    .replace(/-(png|jpg|jpeg|svg|webp)$/, ".$1");

  return `${cdnImageUrl}${fileName}`;
}
