import { memo } from "react";
import { Input } from "../../components/common/input/input";
import { Button } from "../../components/common/button/button";
import { getCurrentYear } from "../../utils/date.utils";
import { useLeadsForm } from "../../form-hooks/use-leads-form";
import { SubscriberLead, LeadType } from "../../model/leads";
import Link from "next/link";
import { LogoIcon } from "../../assets/logo.icon";
import { Contacts } from "../../model/main-page-data";
import { MenuItem } from "../../model/main-page-data";
import { useRouter } from "next/router";
import { VALIDATIONS } from "../../form-hooks/validations";
import { FormCmsData } from "../../model/form";
import * as Sentry from "@sentry/nextjs";

interface Props {
  menu: MenuItem[];
  upButtonTitle: string;
  contacts: Contacts;
  agreementTitle: string;
  privacyTitle: string;
  forms: FormCmsData;
}

export const FooterSection = memo<Props>(
  ({ upButtonTitle, menu, contacts, agreementTitle, privacyTitle, forms }) => {
    const { locale } = useRouter();
    const {
      data,
      submit,
      isLoading,
      onInputChanged,
      isSuccess,
      validationErrors,
    } = useLeadsForm<SubscriberLead>(LeadType.SUBSCRIBER, {
      constraints: {
        requiredFields: ["email"],
        isAddingToParagraphEnabled: true,
        isSendingToNotionEnabled: false,
        customValidations: [VALIDATIONS.VALID_EMAIL],
      },
      crmData: { formId: "Форма подписки" },
      onError: (error) => {
        console.error("error", error);
        alert(forms.alertErrorMessage);
        const errorText = new Error("Форма подписки" + error);
        Sentry.captureException(errorText);
      },
    });

    function handleScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
      <footer className="pl-8 pr-8 pb-20 lg:pr-20 lg:pl-20 text-white max-w-[1440px] xxxl:m-auto">
        <div className="lg:grid grid-cols-3">
          <div className="relative lg:top-[6px]">
            <LogoIcon />
          </div>

          <ul className="pt-24 lg:pt-0">
            {menu.map((item) => (
              <li key={item._key}>
                <Link href={`/#${item.id}`}>{item.title}</Link>
              </li>
            ))}
          </ul>

          <div className="max-w-[500px]">
            <p // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: forms.footer.title,
              }}
              className="pt-30 text-[20px] pb-4 lg:pb-8 leading-[124%] md:text-[28px] md:leading-[110%] lg:pt-0"
            />
            <p // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: forms.footer.subtitle,
              }}
              className="text-night-base-base-500  text-[15px] leading-[20px] pb-16"
            />
            <p className={isSuccess ? "text-base-l pt-10 text-lime" : "hidden"}>
              {forms.footer.successScreenText}
            </p>
            <div role="form" className={isSuccess ? "hidden" : ""}>
              <Input
                theme="secondary-transparent"
                value={data.email}
                name="email"
                placeholder={forms.placeholderEmail}
                onChange={onInputChanged}
              />
              <div className="text-red text-[14px]">
                {validationErrors.length
                  ? `${forms.fillTheFormMessage} `
                  : null}
                {validationErrors.map((error) => error.field).join(", ")}
              </div>
              <Button
                disabled={isLoading}
                onClick={submit}
                className="mt-16 lg:w-max btnSubmitForSeo"
                size="s"
                theme="primary"
              >
                {isLoading ? forms.dataSendWaitMessage : forms.footer.button}
              </Button>
            </div>
          </div>
        </div>
        <div className="lg:grid grid-cols-3 items-end">
          <div>
            <ul className="leading-normal pt-30 lg:pt-14 underline">
              {contacts.list.map((item) => (
                <li key={item._key}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="hidden lg:block pt-20 pb-20 text-sm md:pt-0 md:pb-0 text-[16px] leading-[20px]">
            {getCurrentYear()}©
          </div>
          <div className="lg:flex">
            <ul className="flex text-sm text-night-base-base-400 underline pt-20 lg:mt-0">
              {locale === "ru" && (
                <li className="mr-10 md:mr-5">
                  <Link href="/oferta">Оферта</Link>
                </li>
              )}
              <li className="mr-5">
                <Link href="/agreement">{agreementTitle}</Link>
              </li>
              <li className="mr-5 xxxl:mr-20">
                <Link href="/privacy">{privacyTitle}</Link>
              </li>
            </ul>
            <div
              onClick={handleScrollToTop}
              className="cursor-pointer pt-20 text-sm lg:pt-0 lg:pr-15 hidden xl:flex items-end"
            >
              {upButtonTitle}&nbsp;↑
            </div>
          </div>
        </div>
      </footer>
    );
  }
);
