import { TelegramIcon } from "../../../assets/telegram.icon";
import { memo } from "react";

export const TelegramChat = memo(() => {
  return (
    <div className="fixed bottom-[12px] bg-[#2467E0] right-[20px] lg:right-[35px] z-[999] cursor-pointer p-6 rounded-[50px]">
      <a
        className="relative top-[1px] left-[-2px]"
        href="https://t.me/guidedao_eth"
        target="_blank"
        rel="noreferrer"
      >
        <TelegramIcon />
      </a>
    </div>
  );
});
