import { memo, ReactNode, CSSProperties } from "react";
import { toClassName } from "../../../utils/style.utils";
import { ElementProps } from "../../../types/common";
import Link from "next/link";

type Props = {
  fluid?: boolean;
  size?: keyof typeof sizes;
  theme?: keyof typeof themes;
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
} & LinkProps &
  ElementProps;

type LinkProps =
  | { isLink?: false }
  | { isLink: true; href: string; rel: string; target: string };

const sizes = {
  s: "rounded-[12px] pt-4 pb-4 pl-10 pr-10 text-[15px] h-[40px]",
  m: "rounded-[14px] pt-5 pb-5 pl-10 pr-10 h-[48px] lg:h-[56px] lg:pb-8 lg:pt-8 lg:pr-16 lg:pl-16",
  l: "rounded-[16px] pl-10 pr-10 pt-7 pb-7 lg:pb-0 lg:pt-0 lg:pl-16 lg:pr-16 md:h-[56px] flex items-center justify-center",
};

const themes = {
  "primary-transparent":
    "bg-transparent text-white border border-solid border-white transition-colors hover:bg-night-base-base-100",
  "primary-transparent-light-theme":
    "bg-transparent text-night-base-base-100 border border-solid border-night-base-base-100 transition-colors hover:bg-night-base-base-100",
  accent:
    "bg-lime text-black border border-solid border-lime transition-colors hover:bg-[#AEE218]",
  "accent-blue": "bg-blue text-white transition-colors hover:bg-[#675BEF]",
  "accent-day-blue-ive": "bg-day-blue-ive text-white",
  primary:
    "text-black border-solid bg-white transition-colors hover:bg-night-base-base-800",
  "primary-black":
    "text-white bg-black transition-colors hover:bg-night-background",
};

export const Button = memo<Props>(
  ({
    children,
    size = "m",
    theme = "primary",
    fluid,
    disabled,
    isLink,
    onClick,
    ...props
  }) => {
    const className = toClassName(
      "text-center",
      themes[theme],
      sizes[size],
      fluid && "w-full",
      props.className,
      disabled && "opacity-20"
    );

    if (isLink) {
      return (
        <Link
          {...props}
          href="https://learn.guidedao.xyz/"
          target="_blank"
          className={className}
          role="button"
        >
          {children}
        </Link>
      );
    }

    return (
      <button {...props} className={className} onClick={onClick}>
        {children}
      </button>
    );
  }
);
