import { ElementProps } from "../../../../types/common";

export const TelegramIcon = (props: ElementProps) => (
  <svg
    width={16}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.973.053.34 5.755a.534.534 0 0 0 .043 1.009L4.1 7.862l1.388 4.403c.145.46.728.6 1.065.255l1.923-1.963 3.772 2.77a.787.787 0 0 0 1.236-.474L15.983.908a.753.753 0 0 0-1.01-.855Zm-1.835 2.65L6.342 8.714a.365.365 0 0 0-.12.233l-.262 2.326c-.009.076-.115.086-.138.013l-1.076-3.47a.365.365 0 0 1 .156-.418l8.027-4.978c.184-.115.373.14.21.283Z"
      fill="#2467E0"
    />
  </svg>
);
