import { memo, useCallback, useContext } from "react";
import { Button } from "../button/button";
import { TelegramIcon } from "./assets/telegram.icon";
import { useLeadsForm } from "../../../form-hooks/use-leads-form";
import { StudentLead, LeadType } from "../../../model/leads";
import { CloseIcon } from "./assets/close.icon";
import { useRouter } from "next/router";
import { LottieChicken } from "../../../assets/animation/animated-chicken";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { AtLeastOneFrom } from "../../../form-hooks/use-validation";
import { CountryContext } from "../../../pages/_app";
import { t } from "../../../utils/locale.utils";
import { VALIDATIONS } from "../../../form-hooks/validations";
import { FormCmsData } from "../../../model/form";
import * as Sentry from "@sentry/nextjs";
interface Props {
  onContactPopupSubmitted: () => void;
  orTitle: string;
  formId: string;
  component?: string;
  forms: FormCmsData;
  bootcampName?: string;
}

export const PopUpBlock = memo<Props>(
  ({
    onContactPopupSubmitted,
    orTitle,
    formId,
    component = "default",
    forms,
  }) => {
    const { locale } = useRouter();
    const {
      data,
      submit,
      isLoading,
      isSuccess,
      onInputChanged,
      validationErrors,
    } = useLeadsForm<StudentLead>(LeadType.STUDENT, {
      constraints: {
        requiredFields: [new AtLeastOneFrom("telephone", "telegram"), "name"],
        customValidations: [
          VALIDATIONS.VALID_TELEPHONE,
          VALIDATIONS.VALID_TELEGRAM,
          VALIDATIONS.VALID_NAME,
        ],
        isAddingToParagraphEnabled: false,
        isSendingToNotionEnabled: true,
      },

      crmData: { formId: formId },
      params: {
        locale: locale,
      },
      onError: (error) => {
        console.error("error!", error);
        alert(forms.alertErrorMessage);
        const errorText = new Error(formId + error);
        Sentry.captureException(errorText);
      },
    });

    const onPhoneInputChange = useCallback(
      (phone: string) => onInputChanged({ name: "telephone", value: phone }),
      [onInputChanged]
    );

    const country = useContext(CountryContext);

    return (
      <div className="fixed top-[0px] left-[0px] w-screen h-screen backdrop-blur-2xl z-[9999]">
        <div className="flex items-center justify-center h-full">
          <div className="relative ml-5 mr-5 lg:mr-0 lg:ml-0 bg-white p-12 rounded-[20px] lg:max-w-[450px]">
            <div>
              <div
                className={
                  isSuccess
                    ? "text-[21px] text-center leading-[24px] lg:text-[32px] lg:leading-[112%] text-black"
                    : "hidden"
                }
              >
                <LottieChicken />
                <div
                  className="pt-12 pb-8"
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: forms.successScreenTitle,
                  }}
                />

                <div
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: forms.successScreenSubtitle,
                  }}
                  className="text-center text-[16px] leading-[100%] text-day-base-base-400"
                />
              </div>
              <div>
                <div
                  className={
                    isSuccess
                      ? "hidden"
                      : "text-black text-[32px] leading-[1.2] pr-5 lg:pr-0"
                  }
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: forms.popUp.title,
                  }}
                />
                <div
                  className={
                    isSuccess
                      ? "hidden"
                      : "text-[16px] leading-[1.2] text-day-base-base-400 pt-6 pb-36"
                  }
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: forms.popUp.subtitle,
                  }}
                />

                <div
                  onClick={onContactPopupSubmitted}
                  className="cursor-pointer border border-solid border-day-base-base-200 absolute top-[12px] right-[12px] lg:top-[24px] lg:right-[24px] w-max h-max p-3 rounded-[12px]"
                >
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div
              role="form"
              className={isSuccess ? "hidden" : "consultationForm"}
            >
              <input
                className="text-day-base-base-400 mb-5 w-full rounded-[12px] placeholder-day-base-base-400 bg-day-base-base-100 p-7"
                placeholder={forms.placeholderName}
                name="name"
                value={data.name}
                onChange={onInputChanged}
              />
              <div
                aria-label="Phone input light theme"
                className={`${component === "copyright" ? "copyright" : ""}`}
              >
                <PhoneInput
                  country={country}
                  value={data.telephone}
                  onChange={onPhoneInputChange}
                />
              </div>
              <div className="text-center lg:mt-5 pt-6 pb-6 lg:pt-0 lg:pb-0 text-night-base-base-400 text-[16px] pl-12 pr-12">
                {orTitle?.toLocaleLowerCase()}
              </div>
              <input
                className="text-day-base-base-400 lg:mt-5 w-full rounded-[12px] placeholder-day-base-base-400 bg-day-base-base-100 p-7"
                placeholder={forms.placeholderTelegram}
                name="telegram"
                value={data.telegram}
                onChange={onInputChanged}
              />

              <Button
                onClick={submit}
                className="mt-5 btnSubmitForSeo"
                theme="primary-black"
                size="l"
                fluid
                disabled={isLoading}
              >
                {isLoading ? forms.dataSendWaitMessage : forms.popUp.button}
              </Button>
              <div
                className={
                  validationErrors.length ? "text-red text-[13px]" : "hidden"
                }
              >
                {validationErrors.length
                  ? `${forms.fillTheFormMessage} `
                  : null}
                {validationErrors
                  .map((error) => t(error.field.toString(), { or: orTitle }))
                  .join(", ")}
              </div>
              <div className="lg:flex items-center pt-12 lg:pt-3 text-[15px] text-night-base-base-400 lg:pt-5">
                {forms.selfContact}&nbsp;
                <div className="text-white flex transition-colors hover:text-opacity-80 text-blue">
                  <a
                    href="https://t.me/guidedao_eth"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {forms.selfContactLink}
                  </a>
                  <div className="pl-3 relative top-[3px]">
                    <a
                      href="https://t.me/guidedao_eth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TelegramIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
