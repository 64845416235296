import { ElementProps } from "../../../../types/common";

const CheckIcon = (props: ElementProps) => (
  <svg
    width={12}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 1 4.125 8 1 4.818"
      stroke="#5E7178"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CheckIcon;
