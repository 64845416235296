import { format, addDays, getDay, nextMonday } from "date-fns";
import { ru, enUS, es, pt } from "date-fns/locale";

function getValidDay(date: Date): number {
  const number = getDay(date);
  if (number === 0) {
    return 6;
  }

  return number;
}

export function isToday(date: Date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

const locales = {
  en: enUS,
  ru: ru,
  es: es,
  pt: pt,
};

function isInLocales(locale?: string): locale is keyof typeof locales {
  if (!locale) return false;
  return locale in locales;
}

export function formatDisplay(
  date: Date,
  locale?: string,
  dateFormat: string = "d MMMM"
): string {
  const formatString = locale === "en" ? "MMMM d" : dateFormat;
  const localeObject = isInLocales(locale) ? locales[locale] : enUS;

  if (isToday(date)) {
    return locale === "ru" ? " сегодня" : " today";
  }

  return format(date, formatString, { locale: localeObject });
}

export function getStartCourseDate(
  currentDate: Date,
  courseStartDay: number
): Date {
  let result = currentDate;
  if (getValidDay(currentDate) > courseStartDay + 1) {
    result = nextMonday(result);
    return addDays(result, courseStartDay);
  }

  return addDays(result, courseStartDay - getValidDay(currentDate) + 1);
}

export function getCurrentYear(): number {
  return new Date().getUTCFullYear();
}

export function getCourseStartDate(
  isOptimism: boolean,
  isBlast: boolean,
  blastValue: string,
  todayDate: Date,
  ranking: number,
  locale?: string
) {
  if (isBlast) {
    return blastValue;
  }

  if (isOptimism) {
    return "June 1";
  }

  return formatDisplay(getStartCourseDate(todayDate, ranking), locale);
}
