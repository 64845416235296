import { memo } from "react";
import { Form } from "../../components/common/form/form";

interface Props {
  title: string;
  subtitle: string;
  placeholderName: string;
  placeholderTelegram: string;
  placeholderEmail: string;
  button: string;
  selfContact: string;
  selfContactLink: string;
  successScreenTitle: string;
  successScreenSubtitle: string;
  dataSendWaitMessage: string;
  alertErrorMessage: string;
  fillTheFormMessage: string;
  orTitle: string;
  formId: string;
  styles?: Styles;
  fillSvg?: string;
  bootcampName?: string;
}

interface Styles {
  container: string;
  border: string;
  text: string;
  theme: "light" | "secondary-transparent" | "primary";
}

export const ChooseFormSection = memo<Props>(
  ({
    title,
    subtitle,
    placeholderName,
    placeholderTelegram,
    placeholderEmail,
    button,
    selfContact,
    selfContactLink,
    successScreenTitle,
    successScreenSubtitle,
    dataSendWaitMessage,
    alertErrorMessage,
    fillTheFormMessage,
    orTitle,
    formId,
    styles,
    bootcampName,
    fillSvg = "#fff",
  }) => {
    return (
      <section
        className={`pt-[60px] pb-[1px] lg:pt-[120px] lg:pb-[140px] md:pl-20 pl-6 pr-6 md:pr-20  ${
          styles
            ? styles.container
            : "bg-black bg-center bg-cover bg-[url('/images/index/footer-bg-mobile.png')] lg:bg-[url('/images/index/form-bg-desktop.png')]"
        }`}
      >
        <div
          className={`lg:max-w-[1130px] lg:m-auto relative z-10 ${
            styles ? styles.border : "bg-night-background"
          } mb-40 max-w-screen-xl rounded-[30px] lg:pt-22 lg:pb-22 lg:pl-25 lg:pr-25`}
        >
          <Form
            fillSvg={fillSvg}
            styles={styles}
            formId={formId}
            title={title}
            subtitle={subtitle}
            button={button}
            selfContact={selfContact}
            placeholderEmail={placeholderEmail}
            placeholderTelegram={placeholderTelegram}
            placeholderName={placeholderName}
            selfContactLink={selfContactLink}
            successScreenTitle={successScreenTitle}
            successScreenSubtitle={successScreenSubtitle}
            dataSendWaitMessage={dataSendWaitMessage}
            alertErrorMessage={alertErrorMessage}
            fillTheFormMessage={fillTheFormMessage}
            orTitle={orTitle}
            bootcampName={bootcampName}
          />
        </div>
      </section>
    );
  }
);
