import { ElementProps } from "../../../../types/common";

const ArrowUpIcon = ({ fill = "#fff", ...props }: ElementProps) => (
  <svg
    width={10}
    height={6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.293 5.707a1 1 0 0 0 1.414 0L5 2.414l3.293 3.293a1 1 0 0 0 1.414-1.414l-4-4a1 1 0 0 0-1.414 0l-4 4a1 1 0 0 0 0 1.414Z"
      fill={fill}
    />
  </svg>
);
export default ArrowUpIcon;
