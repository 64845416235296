import { memo, useMemo, useState, useCallback } from "react";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Splide, SplideSlide, Options } from "@splidejs/react-splide";
import { PopUpBlock } from "../pop-up/pop-up";
import { Banner } from "../../../model/banner";
import "@splidejs/splide/css/core";
import { FormCmsData } from "../../../model/form";

interface Props {
  forms: FormCmsData;
  page?: "mainPage" | "innerPage";
  orTitle: string;
  banner: Banner;
  formId: string;
  id?: string;
}

export const BannerWithDiscount = memo<Props>(
  ({ forms, page = "mainPage", orTitle, banner, formId }) => {
    const extensions = useMemo(() => ({ AutoScroll }), []);

    const [isContactSubmitPopupShown, setIsContactSubmitPopupShown] =
      useState<boolean>(false);

    const onContactPopupSubmitted = useCallback(() => {
      setIsContactSubmitPopupShown(false);
    }, []);

    const options = useMemo(
      () =>
        ({
          type: "loop",
          autoWidth: true,
          autoScroll: {
            type: "loop",
            drag: "free",
            focus: "center",
            autoScroll: {
              speed: 1,
            },
          },
        } as Options),
      []
    );

    const handleBannerClick = () => {
      if (page === "mainPage") {
        setIsContactSubmitPopupShown(true);
        return;
      }

      if (page === "innerPage") {
        const priceElement = document.querySelector("#price");
        if (!priceElement) return;

        window.scrollTo({
          top: priceElement.getBoundingClientRect().top + window.scrollY,
          behavior: "smooth",
        });
      }
    };

    const bannerBackground = {
      backgroundColor: banner.color.hex,
    };

    const slides = useMemo(() => {
      return Array.from({ length: 100 }).map((_, index) => (
        <SplideSlide key={index}>
          <div className="text-night-base-base-100 text-[16px] leading-[38px]">
            {index % 2 === 0 ? banner.name : banner.date}
            <span className="bg-night-base-base-100 rounded-[555px] py-2 px-6 mr-8 ml-8 lg:ml-20 lg:mr-20 text-[15px] leading-[20px]">
              <span className="relative top-[-0.5px] text-white">
                -{banner.discount}%
              </span>
            </span>
          </div>
        </SplideSlide>
      ));
    }, [banner.name, banner.date, banner.discount]);

    return (
      <div
        className="relative z-[9000] h-[38px] cursor-pointer"
        style={bannerBackground}
      >
        {page === "mainPage" && isContactSubmitPopupShown && (
          <div className="block cursor-pointer">
            <PopUpBlock
              formId={formId}
              forms={forms}
              orTitle={orTitle}
              onContactPopupSubmitted={onContactPopupSubmitted}
            />
          </div>
        )}
        <div onClick={handleBannerClick} className="uppercase">
          <Splide
            extensions={extensions}
            options={options}
            aria-label="Discount banner"
            className="discount-banner"
          >
            {slides}
          </Splide>
        </div>
      </div>
    );
  }
);

export default BannerWithDiscount;
