import { memo, SyntheticEvent } from "react";
import { ElementProps } from "../../../types/common";

interface Props extends ElementProps {
  text?: "text" | "number";
  placeholder?: string;
  required?: boolean;
  name: string;
  value?: string;
  theme?: keyof typeof themes;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
}

const themes = {
  light:
    "text-day-base-base-400 mb-5 w-full rounded-[12px] placeholder-day-base-base-400 bg-day-base-base-100 px-7 py-9",
  "secondary-transparent":
    "border-b border-solid border-white-opacity-03 text-white focus:text-white bg-transparent",
  primary:
    "bg-night-base-base-100 border rounded-[14px] pt-8 pb-8 pl-8 pr-8 border-solid border-white-opacity-01 text-white focus:text-white",
};

export const Input = memo<Props>(
  ({
    text = "text",
    theme = "primary",
    value,
    onChange,
    placeholder,
    name,
    ...props
  }) => {
    return (
      <div className="text-black lg:mr-10">
        <input
          className={`mb-6 md:mb-0 w-full outline-0 text-[16px] leading-[130%] ${themes[theme]}`}
          value={value}
          type={text}
          name={name}
          placeholder={placeholder}
          {...props}
          onChange={onChange}
        />
      </div>
    );
  }
);
