import { memo, useCallback, useContext } from "react";
import { Input } from "../input/input";
import { Button } from "../button/button";
import { useLeadsForm } from "../../../form-hooks/use-leads-form";
import { StudentLead, LeadType } from "../../../model/leads";
import { TelegramIcon } from "./assets/telegram.icon";
import { useRouter } from "next/router";
import { PhoneInput } from "react-international-phone";
import { AtLeastOneFrom } from "../../../form-hooks/use-validation";
import { CountryContext } from "../../../pages/_app";
import { t } from "../../../utils/locale.utils";
import { VALIDATIONS } from "../../../form-hooks/validations";
import * as Sentry from "@sentry/nextjs";
import "react-international-phone/style.css";

interface Props {
  title: string;
  subtitle: string;
  value?: string;
  placeholderName: string;
  placeholderTelegram: string;
  placeholderEmail: string;
  button: string;
  selfContact: string;
  selfContactLink: string;
  successScreenTitle: string;
  successScreenSubtitle: string;
  dataSendWaitMessage: string;
  alertErrorMessage: string;
  fillTheFormMessage: string;
  orTitle: string;
  formId: string;
  styles?: Styles;
  fillSvg?: string;
  bootcampName?: string;
}

interface Styles {
  container: string;
  border: string;
  text: string;
  theme: "light" | "secondary-transparent" | "primary";
}

export const Form = memo<Props>(
  ({
    title,
    subtitle,
    placeholderTelegram,
    button,
    selfContact,
    selfContactLink,
    successScreenTitle,
    successScreenSubtitle,
    dataSendWaitMessage,
    alertErrorMessage,
    fillTheFormMessage,
    orTitle,
    formId,
    styles,
    fillSvg,
  }) => {
    const { locale } = useRouter();
    const {
      data,
      submit,
      isLoading,
      isSuccess,
      onInputChanged,
      validationErrors,
    } = useLeadsForm<StudentLead>(LeadType.STUDENT, {
      constraints: {
        requiredFields: [new AtLeastOneFrom("telephone", "telegram")],
        customValidations: [
          VALIDATIONS.VALID_TELEPHONE,
          VALIDATIONS.VALID_TELEGRAM,
        ],
        isAddingToParagraphEnabled: false,
        isSendingToNotionEnabled: true,
      },
      crmData: { formId: formId },
      params: {
        locale: locale,
      },
      onError: (error) => {
        console.error("error", error);
        const errorText = new Error(formId + error);
        Sentry.captureException(errorText);
        alert(alertErrorMessage);
      },
    });

    const country = useContext(CountryContext);

    const onPhoneInputChange = useCallback(
      (phone: string) => onInputChanged({ name: "telephone", value: phone }),
      [onInputChanged]
    );

    return (
      <div className="rounded-[16px] pt-20 pb-20 pl-6 pr-6 lg:p-0">
        <div className={isSuccess ? "text-center" : "hidden"}>
          <div
            className={`${
              styles ? styles.text : "text-white"
            } text-[28px] leading-[110%] lg:text-[38px] lg:leading-[1.2]`}
          >
            {successScreenTitle}
          </div>
          <div
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: successScreenSubtitle,
            }}
            className="text-[16px] pt-6 leading-[1.2] text-day-base-base-400"
          />
        </div>
        <div className={isSuccess ? "hidden" : "pb-16 lg:pb-0"}>
          <div
            className={`${
              styles ? styles.text : "text-white"
            } text-[32px] leading-[112%] text-center lg:text-left lg:text-[38px] lg:leading-[42px] `}
          >
            {title}{" "}
            <div
              className="inline lg:block text-day-base-base-400"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </div>
        </div>
        <div className={isSuccess ? "hidden" : ""}>
          <div
            aria-label={styles ? "Phone input light theme" : ""}
            role="form"
            className="md:flex items-center lg:pt-20 consultationForm"
          >
            <PhoneInput
              value={data.telephone}
              onChange={onPhoneInputChange}
              country={country}
            />
            <div className="text-center pt-6 pb-6 lg:pt-0 lg:pb-0 text-night-base-base-400 text-[16px] pl-10 pr-10">
              {orTitle?.toLowerCase()}
            </div>
            <div className="md:min-w-[180px] lg:min-w-[300px] md:mr-10 lg:mr-0 xl:mr-10">
              <Input
                theme={styles ? styles.theme : "primary"}
                value={data.telegram}
                onChange={onInputChanged}
                name="telegram"
                placeholder={placeholderTelegram}
              />
            </div>
            <Button
              className="btnSubmitForSeo text-[16px] leading-[130%] xl:min-w-[250px]"
              disabled={isLoading}
              onClick={submit}
              fluid
              size="l"
              theme="accent-blue"
            >
              {isLoading ? dataSendWaitMessage : button}
            </Button>
          </div>
          <div
            className={
              validationErrors.length ? "text-red text-[13px]" : "hidden"
            }
          >
            {validationErrors.length ? `${fillTheFormMessage} ` : null}
            {validationErrors
              .map((error) => t(error.field.toString(), { or: orTitle }))
              .join(", ")}
          </div>
          <div className="text-center md:flex items-center pt-12 text-[15px] leading-[20px] lg:text-[16px] lg:leading-[130%] text-night-base-base-400">
            {selfContact}&nbsp;
            <div
              className={`${
                styles ? styles.text : "text-white"
              } flex justify-center transition-colors hover:text-opacity-80`}
            >
              <a
                href="https://t.me/guidedao_eth"
                target="_blank"
                rel="noreferrer"
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: selfContactLink,
                }}
              />
              <div className="pl-3 relative top-2">
                <a
                  href="https://t.me/guidedao_eth"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TelegramIcon fill={fillSvg} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
