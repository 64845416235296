import { useCallback, useState } from "react";

export interface ValidationError {
  type: "required";
  field: string | AtLeastOneFrom;
}

export class AtLeastOneFrom {
  fields: string[];
  orTitle: string;

  constructor(...fields: string[]) {
    this.fields = fields;
  }

  hasValidField(
    data: Record<string, any>,
    customValidations: CustomValidation[]
  ): boolean {
    return this.fields.some((field) => {
      const validationRule = customValidations.find(
        (rule) => rule.name === field
      );
      return validationRule ? validationRule.validate(data[field]) : false;
    });
  }

  toString() {
    return this.fields.join(` :or `);
  }
}

export interface CustomValidation {
  name: string;
  validate: (value: string) => boolean;
}

export interface Constraints {
  requiredFields?: (string | AtLeastOneFrom)[];
  customValidations?: CustomValidation[];
  isAddingToParagraphEnabled?: boolean;
  isSendingToNotionEnabled?: boolean;
}

interface Props {
  constraints?: Constraints;
}

function isLengthValid(value: string | null | undefined): boolean {
  if (!value) return false;

  return value.length >= 2;
}

export function useValidation<T extends Record<string, any>>({
  constraints,
}: Props) {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );
  const isAtLeastOneValid = useCallback(
    (data: T, atLeastOneFrom: AtLeastOneFrom): boolean => {
      const validationRules = constraints?.customValidations || [];

      const filledFields = atLeastOneFrom.fields.filter(
        (field) => field in data && data[field]
      );

      const isValidFieldExists = filledFields.some((field) => {
        const rule = validationRules.find((rule) => rule.name === field);
        return rule ? rule.validate(data[field]) : true;
      });

      return isValidFieldExists;
    },
    [constraints?.customValidations]
  );

  const validate = useCallback(
    (data: T) => {
      const currentValidationErrors: ValidationError[] =
        constraints?.requiredFields
          ?.filter((field) =>
            field instanceof AtLeastOneFrom
              ? !isAtLeastOneValid(data, field)
              : !isLengthValid(data[field])
          )
          ?.map((field) => ({ type: "required", field })) || [];

      setValidationErrors(currentValidationErrors);

      return !currentValidationErrors.length;
    },
    [constraints?.requiredFields, isAtLeastOneValid]
  );

  const resetValidation = useCallback(() => {
    setValidationErrors([]);
  }, []);

  return { validationErrors, validate, resetValidation };
}
